import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAlertStore, useUserProfileStore } from "../../store/store";

import {
  IForm,
  IFormErr,
  IOutboundManifestPayload,
} from "../../interfaces/outboundManifest.interface";
import { flightInit, IFlight } from "../../interfaces/flight.interface";
import { IUserProfile } from "../../interfaces/user.interface";
import { getAllFlightsByShipmentDateAPI } from "../../api-services/flight.api";
import { getAllShipmentByFlightAPI } from "../../api-services/shipment.api";
import {
  createOutboundManifestAPI,
  getOutboundManifestByIdAPI,
  updateOutboundManifestAPI,
} from "../../api-services/outboundManifest.api";
import { debug } from "console";

const date = new Date();

const EditOutboundManifestPage = (): ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();

  const formInitial: IForm = {
    shippingDate: [
      date.getFullYear(),
      (date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1,
      date.getDate().toString().length === 1
        ? "0" + date.getDate()
        : date.getDate(),
    ].join("-"),
    selectedFlight: flightInit,
    flight: "",
    tailNo: "",
    owner: "",
    pointOfLoading: "",
    pointOfUploading: "",
    totalPcs: "",
    totalAwb: "",
    grossWt: "",
    localList: [],
    transitList: [],
    staffId: "",
    staffName: "",
  };
  const formErrorInit: any = {
    shippingDate: false,
    localList: [],
    transitList: [],
    staffId: false,
    staffName: false,
  };

  const [flightList, setFlightList] = useState<IFlight[]>([]);
  const [shipmentList, setShipmentList] = useState<any>();
  const [form, setForm] = useState<IForm>(formInitial);
  const [formError, setFormError] = useState<IFormErr>(formErrorInit);
  const [uuid, setUuid] = useState<string>(id || "");

  const userProfile: IUserProfile = useUserProfileStore(
    (state) => state.userProfile
  );
  const updateAlertText = useAlertStore((state) => state.updateAlertText);

  useEffect(() => {
    if (!!form.shippingDate) {
      getAllFlightsByShipmentDate();
    }
  }, [form.shippingDate]);

  useEffect(() => {
    if (!!form.flight && !uuid) {
      getAllShipmentByFlight();
    }
  }, [form.flight]);

  useEffect(() => {
    if (!!uuid) {
      getOutboundManifestById();
    }
  }, []);

  const getAllFlightsByShipmentDate = (): void => {
    const params = { shippingDate: form.shippingDate };

    getAllFlightsByShipmentDateAPI(params).then((res: { payload: any[] }) => {
      setFlightList(!!res ? res.payload : []);
    });
  };

  const getAllShipmentByFlight = (): void => {
    const params = { flightNo: form.flight, shippingDate: form.shippingDate };

    getAllShipmentByFlightAPI(params).then((res: { payload: any }) => {
      setShipmentList(res.payload);
      formInit(res.payload);
    });
  };

  const getOutboundManifestById = (): void => {
    getOutboundManifestByIdAPI(id || "").then((res: { payload: any }) => {
      setShipmentList(res.payload);
      formInit(res.payload);
    });
  };

  const formInit = (data: any): void => {
    data.Local.forEach((shipment: any) => {
      form.localList.push({
        mftPcs: !!uuid ? shipment.mftPcs : shipment.bookedPcs,
        mftWt: !!uuid ? shipment.mftWt : shipment.bookedWt,
        chWt: !!uuid ? shipment.chWt : shipment.chargeableWeight,
        remark: shipment?.remark || "",
      });
      formError.localList.push({ mftPcs: false, remark: false });
    });

    data.Transit.forEach((shipment: any) => {
      form.transitList.push({
        mftPcs: !!uuid ? shipment.mftPcs : shipment.bookedPcs,
        mftWt: !!uuid ? shipment.mftWt : shipment.bookedWt,
        chWt: !!uuid ? shipment.chWt : shipment.chargeableWeight,
        remark: shipment?.remark || "",
      });
      formError.transitList.push({ mftPcs: false, remark: false });
    });

    setForm({
      ...form,
      shippingDate: !!uuid
        ? [
            new Date(data.date).getFullYear(),
            (new Date(data.date).getMonth() + 1).toString().length > 1
              ? new Date(data.date).getMonth() + 1
              : "0" + (new Date(data.date).getMonth() + 1),
            new Date(data.date).getDate().toString().length > 1
              ? new Date(data.date).getDate()
              : "0" + new Date(data.date).getDate(),
          ].join("-")
        : form.shippingDate,
      flight: !!uuid ? data.flightNo : form.flight,
      selectedFlight: {
        ...form.selectedFlight,
        flightNo: !!uuid
          ? data.flightDetails?.flightNo
          : form.selectedFlight.flightNo,
        airline: !!uuid
          ? data.flightDetails?.airline
          : form.selectedFlight.airline,
        station: !!uuid ? data.pointOfLoading : form.selectedFlight.station,
        departureTo: !!uuid
          ? data.pointOfUploading
          : form.selectedFlight.departureTo,
      },
      tailNo: data.tailNo,
      owner: !!uuid ? data.owner : form.selectedFlight.airline,
      pointOfLoading: !!uuid
        ? data.pointOfLoading
        : data.Local.length > 0
        ? data.Local[0].airportOfDeparture
        : data.Transit[0].airportOfDeparture,
      pointOfUploading: !!uuid
        ? data.pointOfUploading
        : data.Local.length
        ? data.Local[0].flightDetails.airportCode
        : data.Transit[0].flightDetails.airportCode,
      totalPcs: !!uuid ? data.totalPcs : getTotalPcs(data),
      totalAwb: !!uuid ? data.totalAwb : getTotalAwb(data),
      grossWt: !!uuid ? data.grossWt : getTotalGrossWeight(data),
      staffId: data.staffId || "",
      staffName: !!uuid ? data.staffName : userProfile.userName,
    });
  };

  const onUpdateForm = (value: any, type: string): void => {
    const updatedForm: any = { ...form };
    const updatedErrorForm: any = { ...formError };
    updatedForm[type] = value;

    if (type === "flight") {
      updatedForm["selectedFlight"] = flightList.find(
        (flight) => flight.id === parseInt(value)
      );
    }

    setForm(updatedForm);
    setFormError(updatedErrorForm);
  };

  const formValidity = (): boolean => {
    return (
      form.shippingDate !== "" &&
      form.staffId !== "" &&
      form.localList.reduce(
        (acc, value) => acc && parseInt(value.mftPcs) > 0,
        true
      )
    );
  };

  const onSave = (): void => {
    const flightNo = flightList.find(
      (flight) => flight.id === parseInt(form.flight)
    )?.id;
    const payload: IOutboundManifestPayload = {
      date: form.shippingDate,
      flightNo: flightNo || 0,
      tailNo: form.tailNo || "",
      owner: form.selectedFlight.airline,
      pointOfLoading: form.pointOfLoading,
      pointOfUploading: form.pointOfUploading,
      totalPcs: form.totalPcs,
      totalAwb: form.totalAwb,
      grossWt: form.grossWt,
      staffId: form.staffId,
      staffName: form.staffName,
      outboundLocal: shipmentList.Local.map(
        (shipment: any, shipmentIdx: number) => ({
          uuid: shipment.uuid,
          cart_uld: "Bulk",
          awbNo: !!uuid ? shipment.awbNo : shipment.airWaybillNumber,
          org: !!uuid ? shipment.org : shipment.airportOfDeparture,
          dest: !!uuid ? shipment.dest : shipment.airportOfDestination,
          // ssc: !!uuid ? shipment.ssc : shipment.description,
          ssc:  shipment.shc ? shipment.shc :'',
          commDesc: !!uuid ? shipment.commDesc : shipment.description,
          shipperUuid: shipment.shipperDetails.uuid,
          consigneeUuid: shipment.consigneeDetails.uuid,
          screeningMethod: shipment.specialHandlingCode,
          mftPcs: form.localList[shipmentIdx].mftPcs,
          mftWt: !!uuid ? shipment.mftWt : shipment.bookedWt,
          chWt: !!uuid ? shipment.chWt : shipment.chargeableWeight.toString(),
          remark: form.localList[shipmentIdx].remark,
        })
      ),
      outboundTransit: shipmentList.Transit.map(
        (shipment: any, shipmentIdx: number) => ({
          uuid: shipment.uuid,
          cart_uld: "Bulk",
          awbNo: !!uuid ? shipment.awbNo : shipment.airWaybillNumber,
          org: !!uuid ? shipment.org : shipment.airportOfDeparture,
          dest: !!uuid ? shipment.dest : shipment.airportOfDestination,
          // ssc: !!uuid ? shipment.ssc : shipment.description,
          ssc:  shipment.shc ? shipment.shc :'',
          commDesc: !!uuid ? shipment.commDesc : shipment.description,
          shipperUuid: shipment.shipperDetails.uuid,
          consigneeUuid: shipment.consigneeDetails.uuid,
          screeningMethod: shipment.specialHandlingCode,
          mftPcs: form.transitList[shipmentIdx].mftPcs,
          mftWt: !!uuid ? shipment.bookedWt : shipment.bookedWt,
          chWt: !!uuid ? shipment.chWt : shipment.chargeableWeight.toString(),
          remark: form.transitList[shipmentIdx].remark,
        })
      ),
    };

    (!!uuid
      ? updateOutboundManifestAPI(uuid, payload)
      : createOutboundManifestAPI(payload)
    ).then(
      (res: { message: string; payload: { outboundManiFestUuid: string } }) => {
        if (!!res.payload) {
          updateAlertText(res.message, "success");
          if (!uuid) {
            setUuid(res.payload.outboundManiFestUuid);
          }
        } else {
          updateAlertText(res.message, "danger");
        }
      }
    );
  };

  const getTotalPcs = (shipment: any): number => {
    const sumReducer = (acc: number, item: any) =>
      acc + parseInt(item.totalNoOfPackages);

    const totalLocal = shipment.Local.reduce(sumReducer, 0);
    const totalTransit = shipment.Transit.reduce(sumReducer, 0);

    return totalLocal + totalTransit;
  };

  const getTotalAwb = (shipment: any): number => {
    const totalLocal = shipment.Local.length;
    const totalTransit = shipment.Transit.length;

    return totalLocal + totalTransit;
  };

  const getTotalGrossWeight = (shipment: any): number => {
    const sumReducer = (acc: number, item: any) =>
      acc + (parseFloat(item.grossWeight) || 0);

    const totalLocal = shipment.Local.reduce(sumReducer, 0);
    const totalTransit = shipment.Transit.reduce(sumReducer, 0);

    return totalLocal + totalTransit;
  };

  const getTotalCount = (
    type: "local" | "transit",
    measure: "mftPcs" | "mftWt" | "chWt"
  ): number => {
    if (type === "local") {
      return form.localList.reduce((acc, value: any) => {
        if (measure === "mftPcs") {
          acc += !!value.mftPcs ? parseInt(value.mftPcs) : 0;
        } else if (measure === "mftWt") {
          acc += !!value.mftWt ? parseFloat(value.mftWt) : 0;
        } else if (measure === "chWt") {
          acc += !!value.chWt ? parseFloat(value.chWt) : 0;
        }
        return acc;
      }, 0);
    } else {
      return form.transitList.reduce((acc, value) => {
        if (measure === "mftPcs") {
          acc += !!value.mftPcs ? parseInt(value.mftPcs) : 0;
        } else if (measure === "mftWt") {
          acc += !!value.mftWt ? parseFloat(value.mftWt) : 0;
        } else if (measure === "chWt") {
          acc += !!value.chWt ? parseFloat(value.chWt) : 0;
        }
        return acc;
      }, 0);
    }
  };

  return (
    <div className="content">
      <div className="card">
        <div className="row">
          <div className="col-2">
            <label id="shipping-date" className="form-label-text d-block mb-1">
              Shipping Date
            </label>
            <input
              id="shipping-date"
              type="date"
              className="form-input-field w-100"
              onChange={(e) => onUpdateForm(e.target.value, "shippingDate")}
              value={form.shippingDate}
            />
          </div>

          <div className="col-2">
            <label id="special-handling-code" className="form-label-text">
              Select Flight <span className="text-danger">*</span>
            </label>
            <select
              className="form-drop-down d-block w-100"
              onChange={(e) => onUpdateForm(e.target.value, "flight")}
              value={form.flight}
            >
              <option value="">Select Flight</option>
              {flightList.map((flight) => (
                <option value={flight.id}>
                  {flight.airline} {flight.flightNo}
                </option>
              ))}
            </select>
          </div>

          {!!shipmentList &&
            (shipmentList.Local.length > 0 ||
              shipmentList.Transit.length > 0) && (
              <Fragment>
                <hr className="mt-4" />

                <div className="col-12 my-3">
                  <div className="row">
                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="flight-number"
                        className="form-label-text d-block mb-1"
                      >
                        Flight Number <span className="text-danger">*</span>
                      </label>
                      <input
                        id="flight-number"
                        type="text"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.selectedFlight.flightNo}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="date"
                        className="form-label-text d-block mb-1"
                      >
                        Date <span className="text-danger">*</span>
                      </label>
                      <input
                        id="date"
                        type="date"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.shippingDate}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="tail-no"
                        className="form-label-text d-block mb-1"
                      >
                        Tail No
                      </label>
                      <input
                        id="tail-no"
                        type="text"
                        placeholder="Enter tail no"
                        className="form-input-field w-100"
                        onChange={(e) => onUpdateForm(e.target.value, "tailNo")}
                        value={form.tailNo}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="owner"
                        className="form-label-text d-block mb-1"
                      >
                        Owner / Operator <span className="text-danger">*</span>
                      </label>
                      <input
                        id="owner"
                        type="text"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.owner}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="point-of-loading"
                        className="form-label-text d-block mb-1"
                      >
                        Point of Loading <span className="text-danger">*</span>
                      </label>
                      <input
                        id="point-of-loading"
                        type="text"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.pointOfLoading}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="point-of-uploading"
                        className="form-label-text d-block mb-1"
                      >
                        Point of Unloading{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        id="point-of-uploading"
                        type="text"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.pointOfUploading}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="total-pcs"
                        className="form-label-text d-block mb-1"
                      >
                        Total Pcs <span className="text-danger">*</span>
                      </label>
                      <input
                        id="total-pcs"
                        type="number"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.totalPcs}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="total-awb"
                        className="form-label-text d-block mb-1"
                      >
                        Total AWB <span className="text-danger">*</span>
                      </label>
                      <input
                        id="total-awb"
                        type="number"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.totalAwb}
                      />
                    </div>

                    <div className="col-md-2 mb-2">
                      <label
                        htmlFor="gross-wt"
                        className="form-label-text d-block mb-1"
                      >
                        Gross Wt <span className="text-danger">*</span>
                      </label>
                      <input
                        id="gross-wt"
                        type="number"
                        className="form-input-field w-100"
                        disabled={true}
                        onChange={() => null}
                        value={form.grossWt}
                      />
                    </div>
                  </div>
                </div>

                <hr />

                {shipmentList.Local.length > 0 && (
                  <div className="col-12">
                    <div className="card-title mb-3">Local</div>
                    <div className="overflow-auto">
                      <div className="table-responsive overflow-visible">
                        <table className="mb-0 w-100">
                          <thead>
                            <tr>
                              <th>CART / ULD</th>
                              <th>AWB No</th>
                              <th>ORG</th>
                              <th>DEST</th>
                              <th>SHC</th>
                              <th>COMM DESC</th>
                              <th>MFT PCS</th>
                              <th>MFT WT (KG)</th>
                              <th>CH WT (KG)</th>
                              <th>Remark</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipmentList.Local.map(
                              (shipment: any, shipmentIdx: number) => (
                                <tr key={"local" + shipmentIdx}>
                                  <td>Bulk</td>
                                  <td>
                                    {!!uuid
                                      ? shipment.awbNo
                                      : shipment.airWaybillNumber}
                                  </td>
                                  <td>
                                    {!!uuid
                                      ? shipment.org
                                      : shipment.airportOfDeparture}
                                  </td>
                                  <td>
                                    {!!uuid
                                      ? shipment.dest
                                      : shipment.airportOfDestination}
                                  </td>
                                  <td>
                                    {!!uuid ? shipment.ssc : shipment.shc}
                                  </td>
                                  <td>
                                    {!!uuid
                                      ? shipment.commDesc
                                      : shipment.description}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-input-field w-100"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        form.localList[shipmentIdx].mftPcs =
                                          value;
                                        setForm({
                                          ...form,
                                          localList: form.localList,
                                        });
                                      }}
                                      value={form.localList[shipmentIdx].mftPcs}
                                    />
                                    {formError.localList[shipmentIdx]
                                      .mftPcs && (
                                      <p className="form-label-text text-danger">
                                        MFT PCS value shouldn't be less than 1
                                      </p>
                                    )}
                                  </td>
                                  <td>{form.localList[shipmentIdx].mftWt}</td>
                                  <td>
                                    {!!uuid
                                      ? shipment.chWt
                                      : shipment.chargeableWeight}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-input-field w-100"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        form.localList[shipmentIdx].remark =
                                          value;
                                        setForm({
                                          ...form,
                                          localList: form.localList,
                                        });
                                      }}
                                      value={form.localList[shipmentIdx].remark}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td>Summary:</td>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td>Total:</td>
                              <td>{getTotalCount("local", "mftPcs")}</td>
                              <td>{getTotalCount("local", "mftWt")}</td>
                              <td>{getTotalCount("local", "chWt")}</td>
                              <td>{""}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <hr className="mt-4" />

                {shipmentList.Transit.length > 0 && (
                  <Fragment>
                    <div className="col-12">
                      <div className="card-title mb-3">Transit</div>
                      <div className="table-responsive overflow-visible">
                        <table className="mb-0 w-100">
                          <thead>
                            <tr>
                              <th>CART / ULD</th>
                              <th>AWB No</th>
                              <th>ORG</th>
                              <th>DEST</th>
                              <th>SCC</th>
                              <th>COMM DESC</th>
                              <th>MFT PCS</th>
                              <th>MFT WT (KG)</th>
                              <th>CH WT (KG)</th>
                              <th>Remark</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipmentList.Transit.map(
                              (shipment: any, shipmentIdx: number) => (
                                <tr key={"transit" + shipmentIdx}>
                                  <td>Bulk</td>
                                  <td>
                                    {!!uuid
                                      ? shipment.awbNo
                                      : shipment.airWaybillNumber}
                                  </td>
                                  <td>
                                    {!!uuid
                                      ? shipment.org
                                      : shipment.flightDetails?.station}
                                  </td>
                                  <td>
                                    {!!uuid
                                      ? shipment.dest
                                      : shipment.airportOfDestination}
                                  </td>
                                  <td>
                                    {!!uuid ? shipment.ssc : shipment.shc}
                                  </td>
                                  <td>
                                    {!!uuid
                                      ? shipment.commDesc
                                      : shipment.description}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-input-field w-100"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        form.transitList[shipmentIdx].mftPcs =
                                          value;
                                        setForm({
                                          ...form,
                                          transitList: form.transitList,
                                        });
                                      }}
                                      value={
                                        form.transitList[shipmentIdx].mftPcs
                                      }
                                    />
                                    {formError.transitList[shipmentIdx]
                                      .mftPcs && (
                                      <p className="form-label-text text-danger">
                                        MFT PCS value shouldn't be less than 1
                                      </p>
                                    )}
                                  </td>
                                  <td>{form.transitList[shipmentIdx].mftWt}</td>
                                  <td>
                                    {!!uuid
                                      ? shipment.chWt
                                      : shipment.chargeableWeight}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-input-field w-100"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        form.transitList[shipmentIdx].remark =
                                          value;
                                        setForm({
                                          ...form,
                                          transitList: form.transitList,
                                        });
                                      }}
                                      value={
                                        form.transitList[shipmentIdx].remark
                                      }
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td>Summary:</td>
                              <td />
                              <td />
                              <td />
                              <td />
                              <td>Total:</td>
                              <td>{getTotalCount("transit", "mftPcs")}</td>
                              <td>{getTotalCount("transit", "mftWt")}</td>
                              <td>{getTotalCount("transit", "chWt")}</td>
                              <td>{""}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <hr className="mt-4" />
                  </Fragment>
                )}

                <div className="card-title mb-3">Prepared By</div>
                <div className="row">
                  <div className="col-md-2 mb-2">
                    <label
                      htmlFor="staff-id"
                      className="form-label-text d-block mb-1"
                    >
                      Staff ID <span className="text-danger">*</span>
                    </label>
                    <input
                      id="staff-id"
                      type="text"
                      placeholder="Enter staff id"
                      className="form-input-field w-100"
                      onChange={(e) => onUpdateForm(e.target.value, "staffId")}
                      value={form.staffId}
                    />
                    {formError.staffId && (
                      <p className="form-label-text text-danger">
                        Please enter staff ID
                      </p>
                    )}
                  </div>

                  <div className="col-md-2 mb-2">
                    <label
                      htmlFor="staff-name"
                      className="form-label-text d-block mb-1"
                    >
                      Staff Name <span className="text-danger">*</span>
                    </label>
                    <input
                      id="staff-name"
                      type="text"
                      placeholder="Enter staff name"
                      className="form-input-field w-100"
                      // disabled={true}
                      onChange={(e) =>
                        onUpdateForm(e.target.value, "staffName")
                      }
                      value={form.staffName}
                    />
                    {formError.staffName && (
                      <p className="form-label-text text-danger">
                        Please enter staff name
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-12 text-right mt-2">
                  <button
                    className="app-btn app-btn-primary me-2"
                    disabled={!formValidity()}
                    onClick={() => onSave()}
                  >
                    Save
                  </button>
                  <button
                    className="app-btn app-btn-primary"
                    disabled={!uuid}
                    onClick={() =>
                      navigate(`/outbound-manifest-invoice/${uuid}`)
                    }
                  >
                    Print
                  </button>
                </div>
              </Fragment>
            )}
        </div>
      </div>
    </div>
  );
};

export default EditOutboundManifestPage;
